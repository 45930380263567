'use strict';

var videoTop = $('.top__img video');
var WaveSurfers = [];

$(document).ready(function(){

    $('.venobox').venobox();

    var musicItem = $('[data-music-init]');
    var musicItemSrc = musicItem.attr('data-music-init');
    var musicItemContainer = musicItem.find('.music__item-waves');

    if ($('[data-music-init]').length) {

        musicItem.each(function(index, item){
            var $this = $(this);
            var src = $this.attr('data-music-init');

            // Create an instance
            var wavesurfer = {};

            wavesurfer = WaveSurfer.create({
                container: this.querySelector('.music__item-waves'),
                waveColor: '#959595',
                progressColor: '#A80F0F',
                cursorColor: 'transparent',
                barWidth: 1,
                barRadius: 0,
                cursorWidth: 1,
                height: 30,
                barGap: 2,
                barHeight: 1.5,
            });

            WaveSurfers.push(wavesurfer);

            console.log(wavesurfer);

            wavesurfer.on('finish', function () {
                wavesurfer.stop();

                $('[data-music-init]').removeClass('is-playing');

                console.log('test');
            });

            // Load audio from URL
            wavesurfer.load(src);

            // Play button
            var button = this.querySelector('[data-action="play"]');

            // button.addEventListener('click', wavesurfer.pause(wavesurfer));
            // button.addEventListener('click', wavesurfer.playPause.bind(wavesurfer));

            button.addEventListener('click', function() {
                // console.log(index)

                var $this = $(this).closest('[data-music-init]');
                var musicItem = $(this).closest('[data-music-init]');

                // console.log(musicItem);

                // var currentItem = $('.music__item').eq(index + 1);

                // console.log(currentItem);

                musicItem.toggleClass('is-playing');
                // musicItem.not(currentItem).removeClass('is-playing');

                // $('.music__item').removeClass('is-playing');

                WaveSurfers.forEach(function(item, i) { 
                    if (i !== index) {
                        item.pause();

                        $('[data-music-init]').eq(i).removeClass('is-playing');
                    }
                });

                wavesurfer.playPause();
            });
        });

        // Init & load audio file
        document.addEventListener('DOMContentLoaded', function() {
            
        });
    }

    $('.top__music-in .btn-video').on('click', function(){
        $('.music__item.is-playing .btn-play').trigger('click');
    });

    $('.top__index-btn .btn').on('click', function(){
        $('body, html').removeClass('is-active--video');
        $('body').addClass('btn-hidden');
        $('body').removeClass('is-active--sound'); 
    });

    $('.top__music-in .btn-video').on('click', function() {
       window.scrollTo(0, 0);
       $('body, html').addClass('is-active--video'); 
       $('body').removeClass('btn-hidden');
       $('body').removeClass('btn-none');
       $('body').addClass('is-active--sound'); 
    });

    if (videoTop.length) {
        videoTop.volume = 0;
    }

    // function toggleMute(){
    //     videoTop.muted = !videoTop.muted;
    // }

    $('.top__index-sound').on('click', function(){
       videoTop.volume = 1;
    });

    setTimeout(function(){
        $('body').addClass('is-active--start');
    }, 100);

    $('.video').on('click', function () {
       $(this).find('.btn-playvideo').addClass('is-hidden');

       var mediaVideo = $(this).closest('.video').find('video').get(0);
       // var mediaVideoLength = $(this).closest('.video').find('video').get(0).duration;

       if (mediaVideo.paused) {
           mediaVideo.play();
       } else {
           mediaVideo.pause();
           $(this).find('.btn-playvideo').removeClass('is-hidden');
      }
    });

    document.addEventListener( 'wpcf7submit', function( event ) {
        setTimeout(function(){
            $('.wpcf7-mail-sent-ok').remove();
        }, 3000);
       
    }, true );


    $('.btn-burger').on('click', function(){
        $(this).toggleClass('is-open');

        $('body, html').toggleClass('menu--opened');

        $('.btn-burger').toggleClass('is-white');
    });


    if ($(window).width() < 1025 && $(window).width() > 769) {
        $('.gallery__items').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 3,
        });
    }

    if ($(window).width() < 769) {
        $('.gallery__items').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true
        });
    }
});

$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    $('.gallery__items').slick('reinit');
})

$(window).on('load', function(){ 
    if ($('.music__items-headline').length) {
        $('.music__items-headline').pin({
            containerSelector: '.music__items-top',
            padding: {bottom: 0},
            minWidth: 1024
        });
    }

    if ($('.single__items-headline').length) {
        $('.single__items-headline').pin({
            containerSelector: '.single__items-in',
            // padding: {bottom: 55},
            minWidth: 1024
        });
    }

    $('body').removeClass('preloader--active');
});

var soundControl = $(this).attr('data-sound');

$(document).ready(function() {
    var musicStart = function(videoTop) {
        if (videoTop.prop('muted')) {
            videoTop.prop('muted', false);
        } else {
            videoTop.prop('muted', true);
        }
    }

    var clickedTime = 0;

    $('.top__index-sound').on('click', function() {
        musicStart(videoTop);

        $(this).toggleClass('is-music');

        $('.top__index-sound span').text('Turn off sound');

        clickedTime++;

        $(this).toggleClass('click-one', clickedTime % 2 == 0);

        if (clickedTime % 2 == 0) {
            localStorage.sound = false; 
            $('.top__index-sound span').text('Turn on sound');
            console.log(localStorage.sound);
        } else {
            if (localStorage == true) {
                localStorage = false;
            } else {
                localStorage.sound = true; 
            }
            
            console.log(localStorage.sound);
        }
    });

    $('.top__index-btn').on('click', function() {
        videoTop.prop('muted', true);
        videoTop.hide();
        videoTop.get(0).pause();
    });

    $('.top__music-in .btn-video').on('click', function(){
        videoTop.show();
        videoTop.get(0).play();

        if (localStorage.sound == "true") {
            $('.top__index-sound').trigger('click');
            videoTop.prop('muted', false);
            $('.top__index-sound').addClass('is-music');
        } else {
            videoTop.prop('muted', true);
            $('.top__index-sound').removeClass('is-music');
        }
    });
});


if($.cookie('visitedNotFirstTime1') === 'yes') {
    $('body').removeClass('is-active--video');
    $('body').addClass('btn-none btn-hidden');

    if ($('.top__index').length) {
        videoTop.get(0).pause();
        videoTop.hide();
    }
} else {

}

$.cookie('visitedNotFirstTime1', 'yes', { expires: 1, path: '/' });


if ($('.top__index').length) {
    if ($(window).width() > 1024) {
        jQuery(function($) {

            // var navHeight = $('.header__r').height();
            var $nav = $('.header__r');
            var $win = $(window);
            var winH = $win.height();   // Get the window height.

            $win.on("scroll", function () {
                if ($(this).scrollTop() > winH ) {
                    $nav.addClass('is-black');
                } else {
                    $nav.removeClass('is-black');
                }
            }).on('resize', function(){ // If the user resizes the window
               winH = $(this).height(); // you'll need the new height value
            });
        }); 
    }
}

if ($(window).width() > 1024) {
    var s = skrollr.init();
}

$('.scroll-link').on('click', function(e){
    e.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 500);
});

